<template>
  <main v-if="loaded">
    <span class="timeline-wrapper">
      <section
        v-if="eventActuallyStarted"
        :class="{ finished: eventData.status != 'CLOSED' }"
      >
        <div class="left">
          <h4>Registration</h4>
          <p>Complete</p>
        </div>
        <div class="middle">
          <span class="timeline-waypoint">
            <Checkmark color="white" />
          </span>
          <span class="dotted reg-complete"></span>
        </div>
        <div class="right"></div>
      </section>
      <section v-else>
        <div class="left">
          <h4>Registration</h4>
          <p v-if="eventData.isRegistrationOpen">Open</p>
          <p v-else-if="eventData.registrationOpenAt != null">
            Opens {{ dateFormatter(eventData.registrationOpenAt) }}
          </p>
          <template v-if="!eventData.isRegistrationOpen">
            <span v-if="eventData.autoOpenRegistration">
              <On class="icon" />Auto open enabled
            </span>
            <span v-else> <Off class="icon" />Auto open disabled </span>
          </template>
        </div>
        <div class="middle">
          <DefaultToggle
            v-model="eventData.isRegistrationOpen"
            @input="toggleRegistration()"
          />
          <span
            class="dotted"
            :class="{ finished: eventData.status != 'CLOSED' }"
          ></span>
        </div>
        <div class="right"></div>
      </section>

      <template v-if="eventData.rounds[0].pools.length == 1">
        <template v-for="(round, roundIndex) in eventData.rounds">
          <section
            v-for="(pool, poolIndex) in round.pools"
            :key="pool.id"
            :class="{ finished: pool.status == 'COMPLETED' }"
          >
            <div class="left" v-if="roundIndex % 2 == 0"></div>
            <div
              class="left"
              :class="{ completed: pool.status == 'COMPLETED' }"
              v-else
            >
              <h4 v-if="round.pools.length > 1">
                R{{ roundIndex + 1 }}-P{{
                  $store.state.poolConversion[poolIndex]
                }}
              </h4>
              <h4 v-else>Round {{ roundIndex + 1 }}</h4>
              <p v-if="pool.status == 'OPEN'">Live</p>
              <p v-else-if="pool.status == 'COMPLETED'">Finished</p>
              <p v-else>{{ dateFormatter(pool.date) }}</p>
              <div class="sub-info" v-if="pool.status == 'CLOSED'">
                <span class="upcoming"></span>
                <span>Upcoming</span>
              </div>
              <div class="sub-info" v-if="pool.status == 'PREPARE'">
                <span class="prepare"></span>
                <span>Finalised</span>
              </div>
            </div>
            <div class="middle">
              <span
                class="timeline-waypoint"
                :class="{ open: pool.status == 'OPEN' }"
              >
                <Checkmark color="white" v-if="pool.status == 'COMPLETED'" />
              </span>
              <span
                class="solid"
                v-if="notLastPool(roundIndex, poolIndex)"
              ></span>
            </div>
            <div class="right" v-if="roundIndex % 2 != 0"></div>
            <div
              class="right"
              :class="{ completed: pool.status == 'COMPLETED' }"
              v-else
            >
              <h4 v-if="round.pools.length > 1">
                R{{ roundIndex + 1 }}-P{{
                  $store.state.poolConversion[poolIndex]
                }}
              </h4>
              <h4 v-else>Round {{ roundIndex + 1 }}</h4>
              <p v-if="pool.status == 'OPEN'">Live</p>
              <p v-else-if="pool.status == 'COMPLETED'">Finished</p>
              <p v-else>{{ dateFormatter(pool.date) }}</p>
              <div class="sub-info" v-if="pool.status == 'CLOSED'">
                <span class="upcoming"></span>
                <span>Upcoming</span>
              </div>
              <div class="sub-info" v-if="pool.status == 'PREPARE'">
                <span class="prepare"></span>
                <span>Finalised</span>
              </div>
            </div>
          </section>
        </template>
      </template>

      <template v-else>
        <template v-for="(round, roundIndex) in eventData.rounds">
          <section
            v-for="(pool, poolIndex) in round.pools"
            :key="pool.id"
            :class="{ finished: pool.status == 'COMPLETED' }"
          >
            <div class="left" v-if="poolIndex % 2 == 0"></div>
            <div
              class="left"
              :class="{ completed: pool.status == 'COMPLETED' }"
              v-else
            >
              <h4 v-if="round.pools.length > 1">
                R{{ roundIndex + 1 }}-P{{
                  $store.state.poolConversion[poolIndex]
                }}
              </h4>
              <h4 v-else>Round {{ roundIndex + 1 }}</h4>
              <p v-if="pool.status == 'OPEN'">Live</p>
              <p v-else-if="pool.status == 'COMPLETED'">Finished</p>
              <p v-else>{{ dateFormatter(pool.date) }}</p>
              <div class="sub-info" v-if="pool.status == 'CLOSED'">
                <span class="upcoming"></span>
                <span>Upcoming</span>
              </div>
              <div class="sub-info" v-if="pool.status == 'PREPARE'">
                <span class="prepare"></span>
                <span>Finalised</span>
              </div>
            </div>
            <div class="middle">
              <span
                class="timeline-waypoint"
                :class="{ open: pool.status == 'OPEN' }"
              >
                <Checkmark color="white" v-if="pool.status == 'COMPLETED'" />
              </span>
              <span
                class="solid"
                v-if="notLastPool(roundIndex, poolIndex)"
              ></span>
            </div>
            <div class="right" v-if="poolIndex % 2 != 0"></div>
            <div
              class="right"
              :class="{ completed: pool.status == 'COMPLETED' }"
              v-else
            >
              <h4 v-if="round.pools.length > 1">
                R{{ roundIndex + 1 }}-P{{
                  $store.state.poolConversion[poolIndex]
                }}
              </h4>
              <h4 v-else>Round {{ roundIndex + 1 }}</h4>
              <p v-if="pool.status == 'OPEN'">Live</p>
              <p v-else-if="pool.status == 'COMPLETED'">Finished</p>
              <p v-else>{{ dateFormatter(pool.date) }}</p>
              <div class="sub-info" v-if="pool.status == 'CLOSED'">
                <span class="upcoming"></span>
                <span>Upcoming</span>
              </div>
              <div class="sub-info" v-if="pool.status == 'PREPARE'">
                <span class="prepare"></span>
                <span>Finalised</span>
              </div>
            </div>
          </section>
        </template>
      </template>
    </span>
  </main>
</template>

<script>
import moment from "moment";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import Checkmark from "@/assets/icons/Checkmark";

export default {
  name: "TheEventTimeline",
  components: {
    DefaultToggle,
    Off,
    On,
    Checkmark,
  },
  data() {
    return {
      registrationOpen: false,
      loaded: false,
      eventData: {},
    };
  },
  watch: {
    $route: function () {
      console.log(this.$route);
      this.loadData();
    },
  },
  computed: {
    eventActuallyStarted() {
      let bool = false;
      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (pool.status == "OPEN" || pool.status == "COMPLETED") {
            bool = true;
          }
        });
      });
      return bool;
    },
    poolsList() {
      let pools = [];

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => pools.push(pool));
      });
      return pools;
    },
  },
  methods: {
    togglePublicGroups(pool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!){
            UpdatePool(poolId:"${pool.id}", input:$UpdatePool){
              id
            }
          }
          `,
          variables: {
            UpdatePool: {
              areGroupsPublic: pool.areGroupsPublic,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
            style: "avocado",
          });
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
            style: "midnight",
          });
        });
    },
    async createGroupsOnly(round) {
      const startingHoles = [];

      round.pools.forEach((pool) => {
        startingHoles.push({
          poolId: pool.id,
          holeId: pool.layoutVersion.holes[0].id,
        });
      });

      console.log(round.pools[0]);

      try {
        if (round.pools[0].startMethod == "TEETIME") {
          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation CreateGroups($StartHoles: [PoolStartHoleInput!]){
                  CreateGroups(roundId:"${round.id}", creationMethod:GROUPS_ONLY, startHoles: $StartHoles){
                    id
                  }
                }`,
              variables: {
                StartHoles: startingHoles,
              },
            },
          });
        } else {
          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation{
                  CreateGroups(roundId:"${round.id}", creationMethod:GROUPS_ONLY){
                    id
                  }
                }
              `,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async makeGroupsPublic() {
      let publiciseQuery = "";

      this.eventData.rounds[0].pools.forEach((pool, index) => {
        let query = `public${index}: UpdatePool(poolId:"${pool.id}", input:$UpdatePool){id}`;
        publiciseQuery += query;
      });

      try {
        let publicGroups = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdatePool($UpdatePool: UpdatePoolInput!){
            ${publiciseQuery}
          }
          `,
            variables: {
              UpdatePool: {
                areGroupsPublic: true,
              },
            },
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async toggleRegistration() {
      var publishTour = "";
      let bool = this.eventData.isRegistrationOpen;

      if (this.eventData.tour.registrationMethod == "GROUPS") {
        let makeGroupsPublic = false;
        let createGroupsOnly = true;

        this.eventData.rounds[0].pools.forEach((pool) => {
          if (pool.groups.length !== 0) {
            createGroupsOnly = false;
          }
          if (pool.areGroupsPublic == false) {
            makeGroupsPublic = true;
          }
        });

        if (createGroupsOnly) {
          await this.createGroupsOnly(this.eventData.rounds[0]);
        }
        if (makeGroupsPublic) {
          await this.makeGroupsPublic();
        }
      }

      if (bool == true && this.eventData.tour.isPublished == false) {
        publishTour = `
            PublishTour(tourId:"${this.eventData.tour.id}"){
              id
            }`;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
              UpdateEvent(eventId:"${this.eventData.id}", input:$UpdateEvent){
                id
              }
              ${publishTour}
            }
          `,
          variables: {
            UpdateEvent: {
              isRegistrationOpen: bool,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.loadData();
          this.$store.dispatch(
            "loadManagedEventData",
            this.$router.currentRoute.params.eventId
          );
        })
        .catch(() => {});
    },
    notLastPool(roundIndex, poolIndex) {
      const roundNumer = roundIndex + 1;
      const poolNumber = poolIndex + 1;

      if (roundNumer < this.eventData.rounds.length) {
        return true;
      }
      if (roundNumer == this.eventData.rounds.length) {
        if (poolNumber < this.eventData.rounds[roundIndex].pools.length) {
          return true;
        }
      }

      return false;
    },
    dateFormatter(date) {
      return moment(date).format("DD MMM [at] HH:mm");
    },
    async loadData() {
      try {
        const eventData = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              name
              status
              isRegistrationOpen
              isVerifyMode
              isResultSigningEnabled
              registrationOpenAt
              autoOpenRegistration
              tour{
                id
                name
                isPublished
                registrationMethod
              }
              rounds{
                id
                status
                pools{
                  id
                  areGroupsPublic
                  position
                  status
                  date
                  position
                  startMethod
                  layoutVersion{
                    holes{
                      id
                    }
                  }
                  groups{
                    id
                  }
                }
              }
            }
          }
          `,
          },
        });

        this.eventData = eventData.data.data.event;
        this.loaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.timeline-wrapper {
  display: block;
  width: 100%;
  background: white;
  padding-top: 32px;
  box-shadow: 0 0 3px $sleet;
  border-radius: 4px;
}
main {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;

  h5 {
    @include Gilroy-Bold;
    font-size: 18px;
    margin: 32px 20px;
  }

  section {
    display: flex;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 18px;
        margin-bottom: 5px;
        padding-top: 3px;
      }
      p {
        font-size: 14px;
      }
    }

    &.finished {
      .left,
      .right {
        h4 {
          color: $blizzard;
        }
        p {
          color: $blizzard;
          font-size: 14px;
        }
      }
      .middle {
        .timeline-waypoint {
          background-color: $blizzard;
          border-color: $blizzard;
        }
        .dotted,
        .solid {
          border-color: $blizzard;
        }
      }
    }

    .left,
    .right {
      width: calc(50% - 30px);
      > span {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .sub-info {
      display: flex;
      align-items: center;

      .upcoming {
        background-color: $sunrise;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .prepare {
        background-color: $sunset;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .open {
        background-color: $grass;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .completed {
        background-color: $midnight;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
      }

      svg {
        margin-right: 8px;
      }
    }

    .left {
      justify-content: flex-start;
      align-items: flex-end;

      p {
        text-align: right;
      }
      span {
        font-size: 12px;
      }
    }
    .right {
      justify-content: flex-start;
      span {
        font-size: 12px;
      }
    }
    .middle {
      width: 60px;
      min-height: 110px;
      align-items: center;

      .dotted {
        height: 100%;
        width: 1px;
        border-right: 1px dotted $midnight;
        margin-top: 6px;

        &.reg-complete {
          height: calc(100% - 27px);
        }
        &.finished {
          border-right: 1px dotted $sleet;
        }
      }
      .solid {
        height: calc(100% - 27px);
        width: 1px;
        border-right: 1px solid $midnight;

        &.finished {
          border-right: 1px solid $sleet;
        }
      }

      .timeline-waypoint {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        border: 1px solid $midnight;
        border-radius: 50%;

        &.open {
          border: 1px solid $midnight;
          background-color: $grass;
        }

        &.finished {
          background-color: $sleet;
          border: 1px solid $sleet;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .timeline-wrapper {
    border-radius: 10px;
    padding-top: 64px;
  }
  main {
    padding: 0px;

    h5 {
      font-size: 20px;
    }

    section {
      > div {
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
        }
      }

      .middle {
        min-height: 150px;
      }
    }
  }
}
</style>

